import { apiBegin } from "@/api/h5";
import { getToken, setToken } from '@/utils/auth'

export default {
  data () {
    return {
      activeName: 1
    };
  },
  methods: {
    dev8080SetToken () {
      setToken('t6jjDCaI5LsqQtAi8xDYkEbTw40', 999999999)
    },
    // 判断顶部筛选区域的过滤条件样式
    judgeFilterClass ({ filed, def = "" }) {
      if (filed === "") {
        return "filter-params-0";
      } else {
        return "filter-params-1";
      }
    },
  }
};
