import http from "../utils/axios";
import { BASE, config, cb } from "./base";

// 采购类型选项
export function apiPurchaseOptions (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/option", params)).then(res => cb(res, callback));
}

// 采购指导价列表
export function apiPurchasePrice (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/price", params)).then(res => cb(res, callback));
}

// 采购指导价详情
export function apiPurchasePriceDtl (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/priceDetail", params)).then(res => cb(res, callback));
}

// 供应商信息列表
export function apiPurchaseCompany (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/company", params)).then(res => cb(res, callback));
}

// 采购合同列表
export function apiPurchaseContract (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/contract", params)).then(res => cb(res, callback));
}

// 采购历史指导价
export function apiPurchasePriceHistory (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/priceTrend", params, "POST")).then(res => cb(res, callback));
}


// 采购历史指导价趋势图
export function apiPurchasePriceTrend (params = {}, callback) {
  http(config(BASE + "erp/purchase/priceTrend", params,)).then(res => cb(res, callback));
}

// 合作方公司历史数据
export function apiPurchaseCompanyHistory (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/companyHistory", params,)).then(res => cb(res, callback));
}

// 采购项目项目列表
export function apiPurchaseProject (params = {}, callback) {
  http(config(BASE + "erp/purchase/project", params,)).then(res => cb(res, callback));
}
// 采购项目合同详情
export function apiPurchaseContractDetail (params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/contractDetail", params,)).then(res => cb(res, callback));
}
