import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
let intergalCarts = localStorage.getItem('intergalCarts')
if (intergalCarts) {
  intergalCarts = JSON.parse(localStorage.getItem('intergalCarts'))
} else {
  intergalCarts = {
    prdctTypeCode: '52301',
    to: 'single',
    single: [],
    self: [],
    selfInfo: {},
    singleInfo: {}
  }
}
const state = {
  errMsg: [],
  listMeta: {}, // 元数据
  user: { prvlgCode: [] }, // 用户信息
  storeId: '', // 商铺Id
  currentStoreId: '',
  pageKey: +new Date(), // 页面key
  carts: [], // 购物车
  singleCart: [], // 临时购物车
  kelanCart: [], // 柯蓝购物车
  points: {
    // 总积分
    totalPoints: '0',
    // 在路上积分
    onTheWayPoints: '0',
    // 失败的积分
    failPoints: '0',
    // 即将过期的积分
    dueDateOfPoints: {
      // 到期日
      dueDate: '',
      // 价值
      amntOrPoints: '0'
    },
    // 优惠卷张数
    nbrOfCoupon: '0',
    // 优惠卷价值
    amntOfCoupon: '0',
    // 即将到期优惠券
    dueDateOfCoupon: {
      // 到期日
      dueDate: '',
      // 价值
      amntOrPoints: '0'
    }
  },
  // 积分详情
  integralInfo: null,
  // 积分商城购物车
  intergalCarts: {
    // 自营或是其他品牌
    prdctTypeCode: '52301',
    // 当前指向哪个购物车
    to: 'single',
    // 直接兑换的加入这购物车
    single: [],
    // 自营产品加入此购物车
    self: [],
    // 购物车相关数据计算
    selfInfo: {},
    singleInfo: {}
  }
}
state.intergalCarts = intergalCarts
const mutations = {
  changeKey (state, value) {
    state.pageKey = value
  },
  changeListMeta (state, value) {
    state.listMeta = value
  },
  changeUser (state, value) {
    state.user = value
  },
  changeStoreId (state, value) {
    state.storeId = value
  },
  changePoints (state, value) {
    state.points = value
  },
  // 更新积分信息
  changeIntergal (state, value) {
    state.integralInfo = value
  }
}
const actions = {
  getListMeta ({ commit }, value) {
    commit('changeListMeta', value)
  },
  getUserInfo ({ commit }, value) {
    commit('changeUser', value)
  },
  getStoreId ({ commit }, value) {
    commit('changeStoreId', value)
  }
}
const getters = {
}
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
