<template>
  <div
    id="app"
    :class="['Index'].includes($route.name) ? 'index--app' : 'no-index--app'"
    class="ao_frame_style"
  >
    <keep-alive>
      <router-view v-if="isRouter" />
    </keep-alive>
    <dj-footer v-if="isRouter" />
    <DjError v-if="!isRouter" />
  </div>
</template>
<script>
import { getToken, setToken } from '@/utils/auth'
import dayjs from 'dayjs'
import { getQueryString } from '@/utils/util'
import mixin from '@/mixins/mixins'
import Cookie from 'js-cookie'
import { wxDomain } from '@/api/base'
// import { wxDomain, cb, wxApi } from '@/api/base'
// import { apiGetToken } from '@/api/BASE-API'
// import { apiTypeName, apiAuth } from '@/api/h5'
import footer from '@/components/config/basis/footer.vue'
import djError from '@/components/djError.vue'
import inobounce from 'inobounce'

export default {
  components: {
    djFooter: footer,
    DjError: djError,
  },
  mixins: [mixin],
  data() {
    return {
      indicator: null,
      value: 2,
      isRouter: false,
      msg: [],
    }
  },
  computed: {
    listMeta() {
      // 新建时处理元数据
      return this.$store.state.listMeta
    },
  },
  watch: {
    listMeta: {
      handler(n, o) {},
      deep: true,
    },
    $route(n, o) {
      this.$nextTick(() => {
      })
    },
  },
  created() {
    const u = navigator.userAgent
    if (u.indexOf('iPhone') > -1) {
    }
    this.isRouter = true

  },
  beforeDestroy() {
  },
  mounted() {
    const this_ = this
    window.addEventListener(
      'popstate',
      (e) => {
        // alert('我监听到了浏览器的返回按钮事件啦')// 根据自己的需求实现自己的功能
        this.$nextTick(() => {
          setTimeout(() => {
            document
              .querySelector('#app')
              .scrollTo(0, this_.$route.meta.position_)
          }, 0)
        })
      },
      false
    )
    // 检测断网
    window.addEventListener('offline', () => {
      this.isRouter = false
      this.$router.push({ name: 'djerror' })
      // this.network= false;
    })
    window.addEventListener('online', () => {
      this.isRouter = true
      this.$router.go(-1)
      // this.network= true;
    })
  },
  methods: {
    goToHome() {
      const rediUrl = getQueryString('rediUrl') || Cookie.get('rediUrl')
      if (rediUrl) {
        this.$router.push({ path: decodeURIComponent(rediUrl) })
      } else {
        const code = getQueryString('code')
        if (code) window.location.href = wxDomain
      }
    },
    // 判断页面的状态
    judgePageStt() {
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          this.msg.push(
            `${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS A')}页面执行了隐藏`
          )
        } else {
          this.msg.push(
            `${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS A')}页面执行了显示`
          )
          setTimeout(() => {}, 2000)
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/css/index_.scss';

#app {
  overflow: hidden auto;
  background-origin: border-box;
  background-clip: border-box;
  background-size: 100%;
  //height: 100%;
  //width: 100%;
  height: 100%;
  background-color: $color-bgc-list;
}
</style>
