import Vue from 'vue'
/*
 * 只允许input输入金额和小数点
 * 1.input 设置 v-money
 * 2.input 设置 type=number
 * 3.input 设置 bindnam值为v-model绑定的name
*/
Vue.directive('money', {
  inserted (el, binding, vnode) {
    let cache = ''
    const name = el.getAttribute('bindname')
    el.addEventListener('focus', function () {
      el.setAttribute('type', 'text')
    })
    el.addEventListener('blur', function () {
      el.setAttribute('type', 'number')
    })
    el.addEventListener('input', function (e) {
      const val = e.target.value
      const reg = /((^[1-9]\d*)|^0)(\.\d*){0,1}$/
      if (!reg.test(val) && val !== '') {
        e.target.value = cache
        el.value = cache
        vnode.context[name] = cache
      } else {
        cache = val
      }
    })
  },
  unbind (el) {
    el.removeEventListener('focus', () => {
    })
    el.removeEventListener('blur', () => {
    })
    el.removeEventListener('input', () => {
    })
  }
})
/*
 * 只允许input输入正整数
 * 1.input 设置 v-integer
 * 2.input 设置 type=number
 * 3.input 设置 bindnam值为v-model绑定的name
*/
Vue.directive('integer', {
  inserted (el, binding, vnode) {
    let cache = ''
    const name = el.getAttribute('bindname')
    el.addEventListener('focus', function () {
      el.setAttribute('type', 'text')
    })
    el.addEventListener('blur', function () {
      el.setAttribute('type', 'number')
    })
    el.addEventListener('input', function (e) {
      const val = e.target.value
      const reg = /^\d+$/
      if (!reg.test(val) && val !== '') {
        e.target.value = cache
        el.value = cache
        vnode.context[name] = cache
      } else {
        cache = val
      }
    })
  },
  unbind (el) {
    el.removeEventListener('focus', () => {
    })
    el.removeEventListener('blur', () => {
    })
    el.removeEventListener('input', () => {
    })
  }
})
