import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect (to) {
        if (to.path === '/') {
          return '/index'
        } else {
          return '/wait'
        }
      }
    },
    {
      name: 'djwait',
      path: '/wait',
      component: () => import('@/components/kmWait')
    },
    {
      name: 'djerror',
      path: '/error',
      // component: () => import("@/components/djError")
      component: require('@/components/djError.vue').default
    },
    // {
    //   name: "test",
    //   path: "/test",
    //   // component: () => import("@/components/djError")
    //   component: require("@/components/test/index.vue").default
    // },
    // 加载页
    // {
    //   name: "loading",
    //   path: "/loading",
    //   component: () => import("@/components/index/loading"),
    //   meta: {
    //     login: false,
    //     title: "加载中..."
    //   }
    // },
    // 首页
    {
      name: 'index',
      path: '/index',
      component: () => import('@/components/index/index'),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: '首页'
      }
    },
    // 指导价
    {
      name: 'price',
      path: '/price',
      component: () => import('@/components/price/index'),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: '指导价列表'
      }
    },
    {
      name: 'steps',
      path: '/steps',
      component: () => import('@/components/price/steps'),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: '历史指导价'
      }
    },
    {
      name: 'pricedtl',
      path: '/pricedtl',
      component: () => import('@/components/price/pricedtl'),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: '指导价详情'
      }
    },
    // 供应商
    {
      name: 'provider',
      path: '/provider',
      component: () => import('@/components/provider/index'),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: '供应商列表页'
      }
    },
    // 供应商信息变更记录
    {
      name: 'changeLog',
      path: '/changeLog',
      component: () => import('@/components/provider/changeLog'),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: '供应商信息变更记录'
      }
    },
    // 供应商变更详情
    {
      name: 'providerDtl',
      path: '/providerDtl',
      component: () => import('@/components/provider/providerDtl'),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: '供应商详情页'
      }
    },
    // 合同
    {
      name: 'contract',
      path: '/contract',
      component: () => import('@/components/contract/index'),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: '合同列表页'
      }
    },
    {
      name: 'contractDtl',
      path: '/contractDtl',
      component: () => import('@/components/contract/contractDtl'),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: '合同详情'
      }
    }
  ],
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err);
// };

export default router
