import { imgDomain } from '@/api/base'
import { apiUserInfo, apiListMeta } from '@/api/BASE-API'
import Cookie from 'js-cookie'
import { showFullScreenLoading, tryHideFullScreenLoading } from '@/utils/loading'

export default {
  data () {
    return {
      imgDomain,
      errorMask: false,
      errorStr: '',
      list: [],
      login: undefined,
      pageConfig: {
        current: 1,
        total: 30
      },
      loading: false,
      finished: false,
      defaultImg: {
        storeLogo: 'saas/2c90071b670c3ce001670c9e5c2b0005.png',
        storeBanner: 'saas/2c90071b670c3ce001670c83bcd10003.png'
      },
      config_: {}
    }
  },
  filters: {
    dateFormatter (date, formatter) {
      date = (date ? new Date(Number(date)) : new Date())
      const Y = date.getFullYear() + ''
      const M = date.getMonth() + 1
      const D = date.getDate()
      const H = date.getHours()
      const m = date.getMinutes()
      const s = date.getSeconds()
      return formatter.replace(/YYYY|yyyy/g, Y)
        .replace(/YY|yy/g, Y.substr(2, 2))
        .replace(/MM/g, (M < 10 ? '0' : '') + M)
        .replace(/DD/g, (D < 10 ? '0' : '') + D)
        .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
        .replace(/mm/g, (m < 10 ? '0' : '') + m)
        .replace(/ss/g, (s < 10 ? '0' : '') + s)
      // return this.dateFormatters(date, formatter)
    }
  },
  methods: {
    // 初始化
    // 通过token获取用户信息
    getUserInfoByToken (token_) {
      return new Promise(resolve => {
        apiUserInfo({ token: token_ }, res => {
          if (res.data.code === 200) {
            this.$store.dispatch('getUserInfo', res.data.data)
            sessionStorage.setItem('r_user', JSON.stringify(res.data.data))
            resolve(res.data.data)
          }
        })
      })
    },
    // 获取源数据
    getListMeta () {
      return new Promise(resolve => {
        apiListMeta(res => {
          let listNum = sessionStorage.getItem('listNum')
          if (!listNum) {
            listNum = 0
          }
          listNum++
          sessionStorage.setItem('listNum', listNum)
          if (res.data.code === 200) {
            this.config_ = res.data.data
            this.$store.dispatch('getListMeta', res.data.data)
            resolve(res.data.data)
          }
        })
      })
    },
    // 获取用户信息和token
    getUserinfoAndDSN (token) {
      const getListMeta = this.getListMeta()
      // const getUserInfo = this.getUserInfoByToken(token);
      return new Promise((resolve, reject) => {
        // Promise.all([getListMeta, getUserInfo]).then(res => {
        Promise.all([getListMeta]).then(res => {
          resolve(res)
        })
      })
    },
    // 处理点击事件错位
    resetClick () {
      setTimeout(function() {
        var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0
        window.scrollTo(0, Math.max(scrollHeight - 1, 0))
      }, 100)
    },
    // 顶部提示
    notify (msg) {
      this.$notify({
        message: msg,
        duration: 1000,
        color: 'white',
        background: '#ff3f45'
      })
    },
    // 跳转页面
    createPage (page) {
      const { name, params, query } = page
      this.$router.push({ name, params, query })
      Cookie.remove('rediUrl')
    },
    // 获取元数据名称
    getCodeDesc (code, item) {
      if (!Array.isArray(item) || code === undefined || code === '') return ''
      const arr = item.filter(ele => ele.value === code)
      if (arr.length > 0) {
        return arr[0].label
      } else {
        return ''
      }
    },
    // 获取元数据名称
    getCodeDesc2 (code, item, val = 'value', type_ = 'number') {
      if (!Array.isArray(item) || code === undefined || code === '') return ''
      let arr = []
      if (type_ === 'number') {
        arr = item.filter(ele => Number(ele[val]) === Number(code))
      } else {
        arr = item.filter(ele => String(ele[val]) === String(code))
      }
      if (arr.length > 0) {
        if (arr[0].hasOwnProperty('label')) {
          return arr[0].label
        } else {
          return arr[0].field_mean
        }
      } else {
        return ''
      }
    },
    // 新版定义
    getDescTrope (val, data, find_field = 'field_value', return_field = 'field_mean') {
      let is_valid = !(data && Array.isArray(data) && data.length > 0)
      if (is_valid) {
        return ''
      }
      if (!return_field) {
        return_field = 'field_mean'
      }
      if (!find_field) {
        find_field = 'field_value'
      }
      let find_res = data.find(fl => {
        if (Object.prototype.toString.call(fl[find_field]) === '[object String]') {
          return String(fl[find_field]) === String(val)
        }
        if (Object.prototype.toString.call(fl[find_field]) === '[object Number]') {
          return Number(fl[find_field]) === Number(val)
        }
      })
      let valid_ = find_res && find_res.hasOwnProperty(return_field)
      if (valid_) {
        return find_res[return_field]
      } else {
        return ''
      }
    },
    /**
     * 获取元数据名称
     * @param val           字段值
     * @param datum         数据源
     * @param queryField    查询的字段
     * @param queryType     查询的类型
     * @param backField     返回的字段
     * @param backDetail    返回的详情
     * @returns {string|*}  返回字符串
     */
    getCodeDesc3 (
      {
        val,
        datum,
        queryField = 'field_value',
        backField = 'field_mean',
        backDetail = 'simple'
      }
    ) {
      // 字符串 [object String]
      // 对 象 [object Object]
      // 数 组 [object Array]
      // [object Null]
      // [object Undefined]
      // 临时数组
      let temp_arr = []
      // 临时对象
      let temp_obj = {}
      // 默认的val 类型题
      let valType = 'noArray'
      let queryType = 'number'
      let temp_val_type = Object.prototype.toString.call(val)
      if (temp_val_type === '[object Array]') {
        valType = 'Array'
      } else if (temp_val_type === '[object Number]') {
      } else if (temp_val_type === '[object String]') {
        queryType = 'str'
      } else {
        return '[error ~ again]: ' + temp_val_type
      }
      // valType 为 Array 时的过滤出的数组
      let Array_filter_res = []
      if (!Array.isArray(datum) || val === undefined || val === '') return ''
      if (valType === 'Array') {
        if (queryType === 'number') {
          let val_ = val.map((ml) => {
            return Number(ml)
          })
          temp_arr = datum.filter(ele => {
            return val_.includes(Number(ele[queryField]))
          })
        } else {
          let val_2 = val.map((ml) => {
            return String(ml)
          })
          temp_arr = datum.filter(le => {
            return val_2.includes(String(le[queryField]))
          })
        }
        temp_arr.forEach((rs) => {
          Array_filter_res.push(rs[backField])
        })
        if (backDetail === 'simple') {
          return Array_filter_res + ''
        } else {
          return {
            res: Array_filter_res + '',
            res_: Array_filter_res,
            res_arr: temp_arr
          }
        }
      } else {
        if (queryType === 'number') {
          temp_obj = datum.find(ele => Number(ele[queryField]) === Number(val))
        } else {
          temp_obj = datum.find(el => String(el[queryField]) === String(val))
        }
        if (temp_obj && Object.keys(temp_obj).length > 0 && temp_obj.hasOwnProperty(backField)) {
          if (backDetail === 'simple') {
            return temp_obj[backField]
          } else {
            return {
              res: temp_obj[backField],
              res_arr: temp_obj
            }
          }
        } else {
          if (backDetail === 'simple') {
            return ''
          } else {
            return {
              res: null,
              res_arr: null
            }
          }
        }
      }
    },
    // 显示错误
    showErr (str) {
      this.errorMask = true
      this.errorStr = str
    },
    // 关闭错误提示
    closeErr (val) {
      this.errorMask = val
    },
    // 处理图片路径
    realAvatar (avatar) {
      if (!avatar) return ''
      if (avatar.includes('http://') || avatar.includes('https://')) {
        return avatar
      } else {
        return this.imgDomain + avatar
      }
    },
    // 加载数据
    loadList (data, count) {
      this.loading = false
      let pageNo = this.params.pageNo
      if (pageNo === 1) {
        this.list = [...data]
      } else {
        this.list.push(...data)
      }
      const len = this.list.length
      this.$set(this.params, 'pageNo', pageNo++)
      this.params.pageNo = pageNo++
      this.pageConfig.current = pageNo
      this.pageConfig.total = count
      this.finished = len >= count
    },
    // 清除实时输入时的前后空格
    setTrim (field) {
      let props
      let len
      let newphoestr
      let item = this
      if (field) {
        props = field.split('.')
        len = props.length
        for (let i = 0; i < len - 1; i++) {
          item = item[props[i]]
        }
        newphoestr = (item[props[len - 1]]).replace(/^ +| +$/g, '')
        this.$set(item, props[len - 1], newphoestr)
      }
    },
    // 重置参数
    resetParams22 () {
      const params = this.params
      for (const prop in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(prop) && prop !== 'pageNo') {
          params[prop] = ''
        }
        params.pageNo = 1
      }
      this.pageConfig.current = 1
      this.pageConfig.total = 0
    },
    // 验证值是否有效
    isValid (value) {
      if (value === '') {
        return false
      }
      if (value === null) {
        return false
      }
      if (value === undefined) {
        return false
      }
      if (value === 0) {
        return false
      }
      return value !== false
    },
    // 验证值是否有效
    isValidNot0 (value) {
      if (value === '') {
        return false
      }
      if (value === null) {
        return false
      }
      if (value === undefined) {
        return false
      }
      return value !== false
    },
    // 图片预加载
    preLoadImg (arr) {
      showFullScreenLoading()
      return new Promise((resolve) => {
        const len = arr.length
        let num = 0
        arr.forEach(item => {
          const img = new Image()
          img.src = item
          if (img.complete) {
            num++
            if (num === len) {
              tryHideFullScreenLoading()
              resolve(num)
            }
          } else {
            img.onload = function() {
              num++
              if (num === len) {
                tryHideFullScreenLoading()
                resolve(num)
              }
            }
            img.onerror = function() {
              num++
              if (num === len) {
                tryHideFullScreenLoading()
                resolve(num)
              }
            }
          }
        })
      })
    }
  },
  computed: {
    opendId () {
      return localStorage.getItem('openid')
    },
    phoneNo () {
      return localStorage.getItem('phone')
    },
    nickname () {
      return localStorage.getItem('nickname')
    },
    avatar () {
      return localStorage.getItem('headimgurl')
    }
  },
  beforeRouteEnter (to, from, next) {
    // let {title} = to.meta
    // title = title || '珂盟美业'
    // if (title) document.title = title
    next()
  }
}
