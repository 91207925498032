<template>
  <div class="">
    <!--      v-if="['index','user','result'].includes($route.name)"-->
    <!--      v-if="!['djerror','djwait'].includes($route.name)"-->
    <van-tabbar
      v-if="['index', 'price', 'contract', 'provider'].includes($route.name)&&hideShow"
      v-model="active"
      active-color="#D81E06"
      class="TABBAR"
      inactive-color="#BFBFBF"
      @change="onChange"
    >
      <van-tabbar-item name="index" replace to="/index?re=1">
        类别
        <template #icon="props">
          <i class="iconfont icon-leibie1 fs40"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="price" replace to="/price?re=1">
        指导价
        <template #icon="props">
          <i class="iconfont icon-xibenzhidaojia fs40"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="provider" replace to="/provider?re=1">
        供应商
        <template #icon="props">
          <i class="iconfont icon-gongyingshang fs40"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="contract" replace to="/contract?re=1">
        合同
        <template #icon="props">
          <i class="iconfont icon-hetong1 fs40"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import h5 from "@/mixins/h5";

export default {
  mixins: [h5],
  data () {
    return {
      active: "index",
      docHeight: document.documentElement.clientHeight,  //默认屏幕高度
      showHeight: document.documentElement.clientHeight,   //实时屏幕高度
      hideShow: true,  //显示或者隐藏footer
    };
  },
  watch: {
    showHeight: function () {
      if (this.docHeight > this.showHeight) {
        this.hideShow = false;
      } else {
        this.hideShow = true;
      }
    },
    $route: {
      handler (n, o) {
        // let active_ = null;
        // if (["index", "price", "contract", "provider"].includes(n.name)) {
        //   active_ = "price";
        // } else if (["pricedtl"].includes(n.name)) {
        //   active_ = "price";
        // } else {
        //   active_ = n.name;
        // }
        this.active = n.name;
      },
      deep: true,
    },
  },
  created () {
  },
  mounted () {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
  },
  methods: {
    onChange (val) {
      this.active = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/var.scss';

.dj-footer {
  height: 1.1rem;
}

.TABBAR {
  background: #fff;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.dj-footer-style {
  box-shadow: 0 0.5rem 0.5rem #000;
}
</style>
