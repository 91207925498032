import http from '../utils/axios'
import { BASE, config, cb } from './base'

// const SERVICE = 'BASE-API/'
// const WECHAT = 'WECHAT-API/'
// const PRD = 'PRD-API/'

// 获取数据
export function apiTypeName (params, callback) {
  http(config(BASE + 'typename', params)).then(res => cb(res, callback))
}

// 获取元数据
export function apiListMeta (callback) {
  http(config(BASE + 'erp/field/all', {})).then(res => cb(res, callback))
}

// 获取用户信息
export function apiUserInfo (params, callback) {
  http(config(BASE + 'erp/auth/receiving', params)).then(res => cb(res, callback))
}

// 鉴权
export function apiAuth (params = {}, callback) {
  http(config(BASE + 'purchase/userinfo', params)).then(res => cb(res, callback))
}

// 捕获前端异常
export function apiJsError (params, callback) {
  http(config(BASE + 'js/error', params, 'POST')).then(res => cb(res, callback))
}
