import dayjs from 'dayjs'
import { toWeek } from '@/utils/util'
export default {
  fullDate (dateTime) {
    if (!dayjs(dateTime).isValid()) return dateTime
    let str = dayjs(dateTime).format('YYYY年MM月DD日')
    str += toWeek(dayjs(dateTime).format('d'))
    str += dayjs(dateTime).format(' HH:mm')
    return str
  },
  date (dateTime, config) {
    if (!dayjs(dateTime).isValid()) return dateTime
    config = config || 'YYYYMMDD'
    return dayjs(dateTime).format(config)
  },
  week (nums) {
    if (!nums) return ''
    let font = ''
    let arrNum = String(nums).split(',')
    const len = arrNum.length
    if (len > 0) arrNum = arrNum.sort((a, b) => a > b)
    for (let i = 0; i < arrNum.length; i++) {
      if (i > 0) font += ','
      font += toWeek(arrNum[i])
    }
    return font
  },
  price (value, unit = '¥', decimal = 2) {
    if (value === undefined || value === null || value === '') return unit + '0.00'
    value = Number(value)
    return unit + value.toFixed(decimal)
  },
  // 千位符
  price3 (value, unit = '¥', decimal = 2) {
    if (value === undefined || value === null || value === '') return unit + '0.00'
    // 截取.后面的数值
    var str = Number(value).toFixed(decimal) + ''
    var strSplitDot = str.split('.')
    var thArr = strSplitDot[0].split('').reverse().reduce((prev, next, index) => {
      return ((index % 3) ? next : (next + ',')) + prev
    })
    if (strSplitDot.length > 1) {
      return unit + thArr + '.' + strSplitDot[1]
    } else {
      return unit + thArr
    }
  },
  toNumber (value, unit = 10000, decimal = 1) {
    if (value === undefined) return ''
    value = Number(value)
    return (value / unit).toFixed(decimal)
  },
  toLock (value, type, isLock) {
    if (!value) return ''
    let str = ''
    const len = value.length
    if (!isLock) {
      switch (type) {
        case 'tel':
          str = value.substring(0, 2) + '******' + value.substring(len - 1, len)
          break
        default:
          str = value.substring(0, 1) + '******' + value.substring(len - 1, len)
      }
    } else {
      str = value
    }
    return str
  },
  speed (value) {
    if (typeof (value) !== 'number') return ''
    if (Number(value) < 1000) {
      return parseInt(value) + 'm'
    } else {
      const str = (value / 1000).toFixed(2) + 'km'
      return str
    }
  },
  toSkin (value) {
    if (!value) return value
    switch (Number(value)) {
      case 1:
        return '黝黑'
      case 2:
        return '古铜'
      case 3:
        return '暗沉'
      case 4:
        return '小麦'
      case 5:
        return '自然'
      case 6:
        return '白皙'
      case 7:
        return '透白'
      default:
        return '绯红'
    }
  }
}
